const state = {
  cart: [],
  order: [],
}

const mutations = {
  add(state, product){
    var qty = product.quantity;
    if(qty > 0) {
      if(state.cart.find(item => item.ItemCode === product.ItemCode)){
        state.cart.find(item => item.ItemCode === product.ItemCode).quantity += qty;
      }else{
        state.cart.push(product);
      }
    }
  },
  delete(state, index){
    state.cart.splice(index, 1);
  },
  clear(state){
    state.cart = [];
  },
  complete(state, cart){
    state.order = cart;
  },
}

export default {
  state,
  mutations
}