const state = {
  token: null,
  role: null,
  name: null,
  menu: null,
}

const mutations = {
  setToken: (state, value) =>
    value ? (state.token = value) : (state.token = null),
  setRole: (state, value) =>
    value ? (state.role = value) : (state.role = null),
  setName: (state, value) =>
    value ? (state.name = value) : (state.name = null),
  setMenu: (state, value) =>
    value ? (state.menu = value) : (state.menu = null),
}

export default {
  state,
  mutations
}