import Vue from "vue";
import Router from "vue-router";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views
const Dashboard = () => import("@/views/Dashboard");

const Colors = () => import("@/views/theme/Colors");
const Typography = () => import("@/views/theme/Typography");

const Charts = () => import("@/views/charts/Charts");
const Widgets = () => import("@/views/widgets/Widgets");

// Views - Components
const Cards = () => import("@/views/base/Cards");
const Forms = () => import("@/views/base/Forms");
const Switches = () => import("@/views/base/Switches");
const Tables = () => import("@/views/base/Tables");
const Tabs = () => import("@/views/base/Tabs");
const Breadcrumbs = () => import("@/views/base/Breadcrumbs");
const Carousels = () => import("@/views/base/Carousels");
const Collapses = () => import("@/views/base/Collapses");
const Jumbotrons = () => import("@/views/base/Jumbotrons");
const ListGroups = () => import("@/views/base/ListGroups");
const Navs = () => import("@/views/base/Navs");
const Navbars = () => import("@/views/base/Navbars");
const Paginations = () => import("@/views/base/Paginations");
const Popovers = () => import("@/views/base/Popovers");
const ProgressBars = () => import("@/views/base/ProgressBars");
const Tooltips = () => import("@/views/base/Tooltips");

// Views - Buttons
const StandardButtons = () => import("@/views/buttons/StandardButtons");
const ButtonGroups = () => import("@/views/buttons/ButtonGroups");
const Dropdowns = () => import("@/views/buttons/Dropdowns");
const BrandButtons = () => import("@/views/buttons/BrandButtons");

// Views - Icons
const CoreUIIcons = () => import("@/views/icons/CoreUIIcons");
const Brands = () => import("@/views/icons/Brands");
const Flags = () => import("@/views/icons/Flags");

// Views - Notifications
const Alerts = () => import("@/views/notifications/Alerts");
//const Badges = () => import('@/views/notifications/Badges')
const Modals = () => import("@/views/notifications/Modals");

// Views - Pages
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
const Login = () => import("@/views/pages/Login");
const Reset = () => import("@/views/pages/Reset");
const ResetPassword = () => import("@/views/pages/ResetPassword");
const Register = () => import("@/views/pages/Register");
const Payment = () => import("@/views/pages/Payment");
const Promotion = () => import("@/views/pages/Promotion");
const PromotionGroups = () => import("@/views/pages/Groups");
const Scenter = () => import("@/views/pages/Scenter");

// Users
const Profile = () => import("@/views/users/Profile");
const Users = () => import("@/views/users/Users");
const User = () => import("@/views/users/User");
const ImportUsers = () => import("@/views/users/ImportUsers");

// Renovations
const Renovation = () => import("@/views/pages/Renovation");
const RenovationBack = () => import("@/views/renovation/Renovation");
const LoadLinks = () => import("@/views/renovation/LoadLinks");

// Liquidations
const Liquidations = () => import("@/views/liquidations/liquidations");
const Liquidation = () => import("@/views/liquidations/liquidation");

//Courses
const Courses = () => import("@/views/course/Courses");
const CreateCourse = () => import("@/views/course/CreateCourse");
const Course = () => import("@/views/course/Course");
const Lessons = () => import("@/views/course/Lessons");
const CreateLessons = () => import("@/views/course/CreateLessons");
const CreateQuiz = () => import("@/views/course/CreateQuiz");
const Quiz = () => import("@/views/course/Quiz");
const QuizResults = () => import("@/views/course/QuizResults");
const AdminCourses = () => import("@/views/admin/course/Courses");
const EditCourse = () => import("@/views/admin/course/EditCourse");

//Commerce
const Products = () => import("@/views/commerce/Products");
const Cart = () => import("@/views/commerce/Cart");
const Complete = () => import("@/views/commerce/Complete");

//Commerce Mayorista
const ProductsM = () => import("@/views/commerceShop/Products");
const CartM = () => import("@/views/commerceShop/Cart");
const CompleteM = () => import("@/views/commerceShop/Complete");

//Reports
const Reports = () => import("@/views/Dashboard");
const Sales = () => import("@/views/pending/Sales");

//Badges
const Badges = () => import("@/views/badge/badges");
const Badge = () => import("@/views/badge/badge");

//Custom links
const CustomLinks = () => import("@/views/customlink/Links");
const CustomLink = () => import("@/views/customlink/Link");

const CustomLinksGroup = () => import("@/views/customlink/LinksGroup");
const CustomLinkGroup = () => import("@/views/customlink/LinkGroup");

//Stock
const Stock = () => import("@/views/stock/Stock");
const Movement = () => import("@/views/stock/Movement");
const MovementClient = () => import("@/views/stock/MovementClient");

//Config
const Config = () => import("@/views/config");
const ConfigProduct = () => import("@/views/config/product");

//Activate masks
const Masks = () => import("@/views/masks/Masks");
const Redeem = () => import("@/views/redeem/Redeem");

//Notification
const Notifications = () => import("@/views/notification/notifications");
const Notification = () => import("@/views/notification/notification");


//Groups
const Groups = () => import("@/views/config/product/groups");
const Group = () => import("@/views/config/product/group");

const Badgeuser = () => import("@/views/badgeuser/Import");


Vue.use(Router);

export default new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

function configRoutes() {
  return [
    {
      path: "/",
      redirect: "/commerceInventory",
      name: "Home",
      component: TheContainer,
      children: [
        {
          path: "course",
          meta: {
            label: "Cursos",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "Cursos",
              component: Courses,
            },
            {
              path: "admin",
              name: "Admin cursos",
              component: AdminCourses,
            },
            {
              path: "create",
              name: "Nuevo Curso",
              component: CreateCourse,
            },
            {
              path: ":id",
              component: {
                render(c) {
                  return c("router-view");
                },
              },
              children: [
                {
                  path: "",
                  name: "Course",
                  component: Course,
                },
                {
                  path: "lessons",
                  name: "Lessons",
                  component: {
                    render(c) {
                      return c("router-view");
                    },
                  },
                  children: [
                    {
                      path: "",
                      name: "Lessons",
                      component: Lessons,
                    },
                    {
                      path: "create",
                      name: "Crear lecciones",
                      component: CreateLessons,
                    },
                    {
                      path: ":lesson_id/edit",
                      name: "Edit Lessons",
                      component: CreateLessons,
                    }
                  ]
                },
                {
                  path: "edit",
                  name: "Editar",
                  component: EditCourse,
                },
                {
                  path: "quiz/create",
                  name: "Create Quiz",
                  component: CreateQuiz,
                },
                {
                  path: "quiz/attempt",
                  name: "Take Quiz",
                  component: Quiz,
                },
                {
                  path: "quiz/results",
                  name: "Quiz result",
                  component: QuizResults,
                },
              ],
            },
          ],
        },
        {
          path: "renovations",
          meta: {
            label: "Renovation",
          },
          name: "Renovation",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "Renovation",
              component: RenovationBack,
            }
          ],
        },
        {
          path: "commerce",
          meta: {
            label: "E-Commerce",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "Products",
              component: Products,
            },
            {
              path: "cart",
              name: "Cart",
              component: Cart,
            },
            {
              path: "complete/:id",
              name: "complete",
              component: Complete,
            },
          ],
        },
        {
          path: "commerceInventory",
          meta: {
            label: "E-Commerce inventario",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "Products",
              component: ProductsM,
            },
            {
              path: "cart",
              name: "Cart",
              component: CartM,
            },
            {
              path: "complete/:id",
              name: "complete",
              component: CompleteM,
            },
          ],
        },
        {
          path: "dashboard",
          name: "Dashboard",
          component: Dashboard,
        },
        {
          path: "theme",
          redirect: "/theme/colors",
          name: "Theme",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "colors",
              name: "Colors",
              component: Colors,
            },
            {
              path: "typography",
              name: "Typography",
              component: Typography,
            },
          ],
        },
        {
          path: "charts",
          name: "Charts",
          component: Charts,
        },
        {
          path: "loadlinks",
          name: "Load Links",
          component: LoadLinks,
        },
        {
          path: "widgets",
          name: "Widgets",
          component: Widgets,
        },
        {
          path: "users",
          meta: {
            label: "Users",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "Users",
              component: Users,
            },
            {
              path: "import",
              meta: {
                label: "Importar usuarios",
              },
              name: "Usuarios",
              component: ImportUsers,
            },
            {
              path: "profile",
              meta: {
                label: "Mi perfil",
              },
              name: "Profile",
              component: Profile,
            },
            {
              path: ":id",
              meta: {
                label: "User Details",
              },
              name: "User",
              component: User,
            },
          ],
        },
        {
          path: "liquidations",
          meta: {
            label: "Pide tus ganancias",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "Liquidations",
              component: Liquidations,
            },
            {
              path: ":id",
              meta: {
                label: "Liquidation Details",
              },
              name: "Liquidation",
              component: Liquidation,
            },
          ],
        },
        {
          path: "customlinks",
          meta: {
            label: "Enlaces personalizados",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "Enlaces personalizados",
              component: CustomLinks,
            },
            {
              path: ":id",
              meta: {
                label: "Enlace personalizado",
              },
              name: "Liquidation",
              component: CustomLink,
            },
          ],
        },
        {
          path: "customlinksgroup",
          meta: {
            label: "Enlaces por grupos",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "Enlaces por grupos",
              component: CustomLinksGroup,
            },
            {
              path: ":id",
              meta: {
                label: "Enlace por grupos",
              },
              name: "New Links",
              component: CustomLinkGroup,
            },
          ],
        },
        {
          path: "badges",
          meta: {
            label: "Medallas",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "Medallas",
              component: Badges,
            },
            {
              path: ":id",
              meta: {
                label: "Detalle medalla",
              },
              name: "Medalla",
              component: Badge,
            },
          ],
        },
        {
          path: "loadbadge",
          meta: {
            label: "Carga medallas",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "Carga medallas",
              component: Badgeuser,
            },
          ],
        },
        {
          path: "groups",
          meta: {
            label: "Grupos",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "Grupos",
              component: Groups,
            },
            {
              path: ":id",
              meta: {
                label: "Detalle grupo",
              },
              name: "Group",
              component: Group,
            },
          ],
        },
        {
          path: "reports",
          meta: {
            label: "Reportes",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "Reportes",
              component: Reports,
            },
          ],
        },
        {
          path: "pendindgsales",
          meta: {
            label: "Ventas pendientes",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "Ventas Pendientes",
              component: Sales,
            },
          ],
        },
        {
          path: "stock",
          meta: {
            label: "Stock",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "Stock",
              component: Stock,
            },
            {
              path: "client",
              meta: {
                label: "Enviar a cliente",
              },
              name: "Movement to client",
              component: MovementClient,
            },
            {
              path: ":id",
              meta: {
                label: "Movement",
              },
              name: "Movement",
              component: Movement,
            },
          ],
        },
        {
          path: "masks",
          meta: {
            label: "Activar licencias",
          },
          component: Masks,
        },
        {
          path: "redeem",
          meta: {
            label: "Canjear licencias",
          },
          component: Redeem,
        },
        {
          path: "config",
          meta: {
            label: "Configuración",
          },
          component: Config,
        },
        {
          path: "config/products",
          meta: {
            label: "Configuración de productos",
          },
          component: ConfigProduct,
        },
        {
          path: "notification",
          meta: {
            label: "Notificaciones",
          },
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "",
              name: "Notificaciones",
              component: Notifications,
            },
            {
              path: ":id",
              meta: {
                label: "Detalle de la notificación",
              },
              name: "Notificación",
              component: Notification,
            },
          ],
        },
        {
          path: "base",
          redirect: "/base/cards",
          name: "Base",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "cards",
              name: "Cards",
              component: Cards,
            },
            {
              path: "forms",
              name: "Forms",
              component: Forms,
            },
            {
              path: "switches",
              name: "Switches",
              component: Switches,
            },
            {
              path: "tables",
              name: "Tables",
              component: Tables,
            },
            {
              path: "tabs",
              name: "Tabs",
              component: Tabs,
            },
            {
              path: "breadcrumbs",
              name: "Breadcrumbs",
              component: Breadcrumbs,
            },
            {
              path: "carousels",
              name: "Carousels",
              component: Carousels,
            },
            {
              path: "collapses",
              name: "Collapses",
              component: Collapses,
            },
            {
              path: "jumbotrons",
              name: "Jumbotrons",
              component: Jumbotrons,
            },
            {
              path: "list-groups",
              name: "List Groups",
              component: ListGroups,
            },
            {
              path: "navs",
              name: "Navs",
              component: Navs,
            },
            {
              path: "navbars",
              name: "Navbars",
              component: Navbars,
            },
            {
              path: "paginations",
              name: "Paginations",
              component: Paginations,
            },
            {
              path: "popovers",
              name: "Popovers",
              component: Popovers,
            },
            {
              path: "progress-bars",
              name: "Progress Bars",
              component: ProgressBars,
            },
            {
              path: "tooltips",
              name: "Tooltips",
              component: Tooltips,
            },
          ],
        },
        {
          path: "buttons",
          redirect: "/buttons/standard-buttons",
          name: "Buttons",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "standard-buttons",
              name: "Standard Buttons",
              component: StandardButtons,
            },
            {
              path: "button-groups",
              name: "Button Groups",
              component: ButtonGroups,
            },
            {
              path: "dropdowns",
              name: "Dropdowns",
              component: Dropdowns,
            },
            {
              path: "brand-buttons",
              name: "Brand Buttons",
              component: BrandButtons,
            },
          ],
        },
        {
          path: "icons",
          redirect: "/icons/coreui-icons",
          name: "CoreUI Icons",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "coreui-icons",
              name: "Icons library",
              component: CoreUIIcons,
            },
            {
              path: "brands",
              name: "Brands",
              component: Brands,
            },
            {
              path: "flags",
              name: "Flags",
              component: Flags,
            },
          ],
        },
        {
          path: "notifications",
          redirect: "/notifications/alerts",
          name: "Notifications",
          component: {
            render(c) {
              return c("router-view");
            },
          },
          children: [
            {
              path: "alerts",
              name: "Alerts",
              component: Alerts,
            },
            {
              path: "badges",
              name: "Badges",
              component: Badges,
            },
            {
              path: "modals",
              name: "Modals",
              component: Modals,
            },
          ],
        },
      ],
    },
    {
      path: "/pages",
      redirect: "/pages/404",
      name: "Pages",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "404",
          name: "Page404",
          component: Page404,
        },
        {
          path: "500",
          name: "Page500",
          component: Page500,
        },
        {
          path: "login",
          name: "Login",
          component: Login,
        },
        {
          path: "scenter/:id",
          name: "Scenter",
          component: Scenter,
        },
        {
          path: "reset",
          name: "Reset",
          component: Reset,
        },
        {
          path: "password/reset/:token/:email",
          name: "ResetPassword",
          component: ResetPassword,
        },
        {
          path: "Payment/:id",
          name: "Pago",
          component: Payment,
        },
        {
          path: "Promotion/:id",
          name: "Promotion",
          component: Promotion,
        },
        {
          path: "groups/:id",
          name: "Realiza tu compra",
          component: PromotionGroups,
        },
        {
          path: "renovation/:id",
          name: "Renovation",
          component: Renovation,
        },
        {
          path: "renovation",
          name: "Renovation",
          component: Renovation,
        },
        {
          path: "register",
          name: "Register",
          component: Register,
        },
      ],
    },
  ];
}
