import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
// import { iconsSet as icons } from './assets/icons/icons.js'
import * as icons from '@coreui/icons';
import CIcon from '@coreui/icons-vue'
import store from './store'
import VueCookies from 'vue-cookies'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import moment from 'moment-timezone';
import VueMoment from 'vue-moment';
import Hotjar from 'vue-hotjar';
import VueGtm from '@gtm-support/vue2-gtm';
import VueGtag from 'vue-gtag'

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console)
Vue.use(VueCookies)
library.add( fas )
Vue.component('font-awesome-icon', FontAwesomeIcon)

moment.tz.setDefault(moment.tz.guess());
require('moment/locale/es');

Vue.use(VueMoment, {moment});

Vue.use (Hotjar, {
  id: '3152670',
  isProduction: process.env.VUE_APP_PAYMENTEZ_ENV == 'prod'
})

Vue.use(VueGtm, {
  id: 'GTM-MNWR9NL', // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
  enabled: (process.env.VUE_APP_PAYMENTEZ_ENV == 'prod'), // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
  vueRouter: router, // Pass the router instance to automatically sync with router (optional)
})

Vue.use(VueGtag, {
  config: {
      id: process.env.VUE_APP_ANALYTICS,
      params: {
          send_page_view: false
      }
  }
}, router)

new Vue({
  el: '#app',
  router,
  store,
  icons,
  CIcon,
  template: '<App/>',
  components: {
    App
  },
  created() {
    this.checkAndClearCart();
  },
  methods: {
    checkAndClearCart() {
      const lastCartUpdate = this.$cookies.get('lastCartUpdate');
      const today = new Date().toLocaleDateString();

      if (!lastCartUpdate || lastCartUpdate !== today) {
        this.$store.commit('clear');
        this.$cookies.set('lastCartUpdate', today, { expires: '1D' });
      }
    },
  },
})
